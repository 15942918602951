import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchOrders } from "features/orders/orderSlice";
import { fetchUsers } from "features/user/userSlice";
import axios from 'axios';

const MainDash: React.FC = () => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state) => state.orders.orders);
  const { users, userInfo, loading, error } = useAppSelector(
    (state) => state.users
  );
  const calculateTotalAmountEarned = () => {
    // Filter orders that have status 'Paid'
    const paidOrders = orders.filter((order) => order.status === "Paid");

    // Calculate total amount earned from 'Paid' orders
    const totalEarned = paidOrders.reduce(
      (accumulator, order) => accumulator + order.total,
      0
    );

    return totalEarned.toFixed(2); // Return as a formatted string
  };

  const totalOrdersCount = () => {
    return orders.length;
  };

  const pendingOrdersCount = () => {
    const pendingOrders = orders.filter((order) => order.status === "Pending");
    return pendingOrders.length;
  };

  const completedOrdersCount = () => {
    const completedOrders = orders.filter(
      (order) => order.status === "Completed"
    );
    return completedOrders.length;
  };

  const totalUserCount = () => {
    return users.length;
  };
  useEffect(() => {
    dispatch(fetchOrders());
    dispatch(fetchUsers());
  }, [dispatch]);





  return (
    <div className="p-5">
      <h1 className="text-2xl font-bold mb-4">Main Dashboard</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="flex flex-col items-center justify-center h-32 bg-gray-200 rounded-lg shadow-md p-4">
          <p className="text-lg font-semibold  text-black">
            Total Amount Earned
          </p>
          <p className="text-xl text-black">
            AED&nbsp;{calculateTotalAmountEarned()}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center h-32 bg-gray-200 rounded-lg shadow-md p-4">
          <p className="text-lg font-semibold text-black">Total Orders</p>
          <p className="text-xl text-black">{totalOrdersCount()}</p>
        </div>
        <div className="flex flex-col items-center justify-center h-32 bg-gray-200 rounded-lg shadow-md p-4">
          <p className="text-lg font-semibold text-black">Pending Orders</p>
          <p className="text-xl text-black">{pendingOrdersCount()}</p>
        </div>
        <div className="flex flex-col items-center justify-center h-32 bg-gray-200 rounded-lg shadow-md p-4">
          <p className="text-lg font-semibold text-black">Completed Orders</p>
          <p className="text-xl text-black">{completedOrdersCount()}</p>
        </div>
        <div className="flex flex-col items-center justify-center h-32 bg-gray-200 rounded-lg shadow-md p-4">
          <p className="text-lg font-semibold text-black">Total Users</p>
          <p className="text-xl text-black">{totalUserCount()}</p>
        </div>
      </div>
    </div>
  );
};

export default MainDash;
