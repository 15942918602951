import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  FC,
  useEffect,
} from "react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
  onAuthStateChanged,
  ConfirmationResult,
  Auth,
} from "firebase/auth";
import { auth } from "../utils/firebaseConfig"; // Ensure this is the correct path to your Firebase config
import axios from "axios";

declare global {
  interface Window {
    _recaptchaVerifier?: RecaptchaVerifier;
    _confirmationResult?: ConfirmationResult;
    _captchaWidgetId?: number;
    grecaptcha?: {
      reset: (widgetId: number) => any;
    };
  }
}

interface User {
  email: string; // No longer optional
  phoneNumber?: string; // No longer optional
  name: string; // No longer optional
  _id: string;
  token: string;
}

interface UserContextProps {
  user: User | null;
  login: (userData: User) => void;
  logout: () => void;
  setUpRecaptcha: (phoneNumber: string) => Promise<void>;
  loginWithPhone: (otp: string) => Promise<void>;
}

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null);
  const [confirmationResult, setConfirmationResult] = useState<any>(null);

  const login = (userData: User) => {
    localStorage.setItem("user", JSON.stringify(userData));
    setUser(userData);
  };

  const logout = async () => {
    await signOut(auth);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(null);
  };

  const setUpRecaptcha = async (phoneNumber: string) => {
    try {
      // Check if reCAPTCHA is already initialized
      if (!window._recaptchaVerifier) {
        window._recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          { size: "invisible" }
        );
        await window._recaptchaVerifier.render();
      } else {
        // Reset the existing reCAPTCHA instance
        window._recaptchaVerifier.clear();
        await window._recaptchaVerifier.render();
      }

      // Proceed with phone number sign-in
      const result = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        window._recaptchaVerifier
      );
      setConfirmationResult(result); // Store the confirmation result for OTP verification
    } catch (error) {
      console.error("Error during OTP setup:", error);
      throw error; // Re-throw error for handling in the component
    }
  };

  const loginWithPhone = async (otp: string) => {
    if (confirmationResult) {
      try {
        const result = await confirmationResult.confirm(otp);
        const idToken = await result.user.getIdToken();

        const isPhoneNumber =
          result.user.phoneNumber?.startsWith("0") &&
          result.user.phoneNumber.length === 13;

        const formattedPhoneNumber = isPhoneNumber
          ? `+971${result.user.phoneNumber.slice(1)}`
          : result.user.phoneNumber || "";
        // Check if user is registered with the required info
        const isRegistered = await checkIfUserIsRegistered(
          formattedPhoneNumber
        );
        if (!isRegistered) {
          throw new Error("User is not registered. Please sign up first.");
        }
        // Send the ID token and phone number to your backend to verify OTP and get user profile
        const response = await axiosInstance.post("/api/users/verify-otp", {
          idToken,
          phone: formattedPhoneNumber,
        });

        const userData: User = {
          email: response.data.email || "", // Provide fallback to empty string
          phoneNumber: response.data.phoneNumber, // Provide fallback to empty string
          name: response.data.name, // Provide fallback to empty string
          _id: response.data._id, // Assumed to be defined
          token: response.data.token,
        };

        // Save user data to localStorage and update state
        login(userData); // Call the existing login function
        setUser(userData);
      } catch (error) {
        console.error("Error during phone OTP login:", error);
        throw error; // Handle error in the component
      }
    }
  };

  const checkIfUserIsRegistered = async (
    phoneNumber: string
  ): Promise<boolean> => {
    try {
      const response = await axiosInstance.get(`/api/users/${phoneNumber}`);

      // axios responses have data directly
      const data = response.data;

      return data.isRegistered || false; // This depends on your backend structure
    } catch (error: any) {
      // Check if it's a 404 error and return false (user not found)
      if (error.response && error.response.status === 404) {
        return false; // Not registered
      }

      console.error("Error checking if user is registered:", error);
      return false; // Return false on any other error
    }
  };

  useEffect(() => {

    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken(); // Wait for the token to be resolved
          const userData: User = {
            email: currentUser.email || "", // Provide fallback to empty string
            phoneNumber: currentUser.phoneNumber || "", // Provide fallback to empty string
            name: currentUser.displayName || "", // Provide fallback to empty string
            _id: currentUser.uid, // Use UID if available
            token, // Set the resolved token
          };
          setUser(userData); // Update user state with all properties
          localStorage.setItem("user", JSON.stringify(userData))
        } catch (error) {
          console.error("Error fetching token:", error);
        }
      } else if (!storedUser) {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{ user, login, logout, setUpRecaptcha, loginWithPhone }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
