//frontend/src/utils/NotificationService.js
import axios from "axios";


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});
export const sendTokenToBackend = async (token) => {
    const storedToken = localStorage.getItem("token"); // Retrieve the token from local storage
    console.log("Sending FCM token to backend:", token); // Log the token

    try {
        const response = await axiosInstance.post("/api/users/fcm-token", { fcmToken: token }, {
            headers: {
                Authorization: `Bearer ${storedToken}` // Include the token in the headers
            }
        });
        console.log("FCM token successfully sent to backend:", response.data);
    } catch (error) {
        console.error("Error sending FCM token to backend:", error.response || error);
    }
};
