// AdsManager.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import Input from "shared/Input/Input";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { createAd, deleteAd, editAd, fetchAds } from "features/ads/adsSlice"; // Assume you'll create this slice
import { NewAd, Ad } from "data/types";
import { uploadImage } from "utils/cloudinary";

const AdsManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const { ads, loading, error } = useAppSelector((state) => state.ads);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [adForm, setAdForm] = useState<NewAd>({
    title: "",
    image: "",
    link: "",
    isActive: true,
    displayOrder: 1,
  });

  const [editedAd, setEditedAd] = useState<Ad | null>(null);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const { name, value } = target;

    if (target.type === "file") {
      const files = target.files;
      if (files) {
        setImageFile(files[0]);
      }
    } else {
      setAdForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const openEditModal = (adToEdit: Ad) => {
    setAdForm({
      title: adToEdit.title,
      image: adToEdit.image,
      link: adToEdit.link,
      isActive: adToEdit.isActive,
      displayOrder: adToEdit.displayOrder,
    });
    setEditedAd(adToEdit);
    setIsModalOpen(true);
  };

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });



  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Submit button clicked");

    let imageUrl = adForm.image;

    if (imageFile) {
      console.log("Uploading image...");
      try {
        imageUrl = await uploadImage(imageFile);
        console.log("Image uploaded:", imageUrl);
      } catch (error) {
        console.error("Image upload failed:", error);
        alert("Image upload failed. Please try again.");
        return; // Exit if upload fails
      }
    } else {
      console.log("No image file to upload.");
    }

    const adData = {
      ...adForm,
      image: imageUrl,
    };

    console.log("Submitting service data:", adData);

    if (editedAd) {
      await dispatch(editAd({ adId: editedAd._id, data: adData }));
      console.log("Service edited:", editedAd._id);
    } else {
      await dispatch(createAd(adData));
      console.log("Service created");
    }

    closeModal();
    dispatch(fetchAds()); // Fetch services after create/edit
  };

  const deleteHandler = async (adId: string) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      const adToDelete = ads.find(ad => ad._id === adId);

      if (adToDelete) {
        try {
          await dispatch(deleteAd(adId));
          dispatch(fetchAds()); // Fetch updated services
        } catch (error) {
          console.error("Deletion failed:", error);
          alert("Failed to delete service. Please check the console for details.");
        }
      } else {
        console.error("Service not found for ID:", adId);
        alert("Service not found.");
      }
    }
  };

  useEffect(() => {
    dispatch(fetchAds());
  }, [dispatch]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setImageFile(null);
    setEditedAd(null);
  };

  return (
    <div className="p-5">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Ads</h2>
          <ButtonPrimary onClick={openModal} className="z-10">
            Create Ad
          </ButtonPrimary>
        </div>
        <table className="min-w-full table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Image</th>
              <th className="px-4 py-2">Title</th>
              <th className="px-4 py-2">Link</th>
              <th className="px-4 py-2">Active</th>
              <th className="px-4 py-2">Order</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {ads.map((adItem: Ad) => (
              <tr key={adItem._id}>
                <td className="border px-4 py-2">
                  <img
                    src={
                      adItem.image
                    }
                    alt={adItem.title}
                    className="h-9 w-16"
                  />
                </td>
                <td className="border px-4 py-2">{adItem.title}</td>
                <td className="border px-4 py-2">{adItem.link}</td>
                <td className="border px-4 py-2">
                  {adItem.isActive ? "Yes" : "No"}
                </td>
                <td className="border px-4 py-2">{adItem.displayOrder}</td>
                <td className="border px-4 py-2">
                  <button
                    className="text-blue-500 hover:text-blue-700 mr-2"
                    onClick={() => openEditModal(adItem)}
                  >
                    Edit
                  </button>
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() => deleteHandler(adItem._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <p>Loading ads...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <form onSubmit={handleSubmit}>
            <div className="p-4">
              <h2 className="text-lg font-semibold mb-4">
                {editedAd ? "Edit Ad" : "Create Ad"}
              </h2>
              {/* Title input */}
              <div className="mb-4">
                <label className="block mb-2">Title</label>
                <Input
                  name="title"
                  placeholder="Ad Title"
                  value={adForm.title}
                  onChange={handleInputChange}
                />
              </div>
              {/* Link input */}
              <div className="mb-4">
                <label className="block mb-2">Link</label>
                <Input
                  name="link"
                  placeholder="Ad Link"
                  value={adForm.link}
                  onChange={handleInputChange}
                />
              </div>
              {/* Image input */}
              <div className="mb-4">
                <label className="block mb-2">Image(16:9 Aspect Ratio)</label>
                <label className="block mb-2 text-sm">
                  Ex: 400x225px, 640x360px etc
                </label>
                <Input
                  name="image"
                  placeholder="Upload Image"
                  type="file"
                  onChange={handleInputChange}
                />
              </div>
              {/* Display Order input */}
              <div className="mb-4">
                <label className="block mb-2">Display Order</label>
                <Input
                  name="displayOrder"
                  type="number"
                  placeholder="Display Order"
                  value={adForm.displayOrder}
                  onChange={handleInputChange}
                />
              </div>
              {/* Active checkbox */}
              <div className="mb-4">
                <label className="block mb-2">Active</label>
                <input
                  type="checkbox"
                  name="isActive"
                  checked={adForm.isActive}
                  onChange={(e) =>
                    setAdForm((prevState) => ({
                      ...prevState,
                      isActive: e.target.checked,
                    }))
                  }
                />
              </div>
              <ButtonPrimary type="submit">Submit</ButtonPrimary>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default AdsManager;
