import React, { FC, useEffect, useRef } from "react";
import AdsCard, { Ad } from "./AdsCard";
import { fetchActiveAds } from "../features/ads/adsSlice"; // Make sure this slice exists
import { useAppDispatch, useAppSelector } from "app/hooks";

const Ads: FC = () => {
  const dispatch = useAppDispatch();
  const { ads, loading, error } = useAppSelector((state) => state.ads);
  const containerRef = useRef<HTMLDivElement>(null);
  const currentIndex = useRef(0);

  useEffect(() => {
    dispatch(fetchActiveAds());
  }, [dispatch]);

  useEffect(() => {
    if (!Array.isArray(ads) || ads.length === 0) return;
    const intervalId = setInterval(() => {
      const container = containerRef.current;
      if (!container) return;

      //scroll to next ad
      currentIndex.current = (currentIndex.current + 1) % ads.length;
      container.scrollTo({
        left: currentIndex.current * 320,
        behavior: "smooth",
      })
    }, 3000)
    return () => { clearInterval(intervalId) }
  }, [ads])
  if (loading) return <div>Loading ads...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div ref={containerRef} className="overflow-x-auto flex gap-4 px-4">
      {Array.isArray(ads) && ads.map((ad) => (
        <div className="flex-none w-80" key={ad._id}>
          <AdsCard ad={ad} />
        </div>
      ))}
    </div>

  );
};

export default Ads;
