import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Review } from 'data/types';
import { approveReviews, getAllReviews } from 'features/review/reviewSlice';
import React, { useEffect, useState } from 'react';

const ReviewManager: React.FC = () => {
    const dispatch = useAppDispatch();
    const { approvedReviews, loadingReview, errorReview } = useAppSelector((state) => state.reviews);
    const [localApprovedReviews, setLocalApprovedReviews] = useState<Review[]>([]);

    useEffect(() => {
        // Fetch all reviews from the database
        dispatch(getAllReviews()).then((action) => {
            if (getAllReviews.fulfilled.match(action)) {
                const allReviews: Review[] = action.payload;
                setLocalApprovedReviews(allReviews); // Set the local state to all fetched reviews
            }
        });
    }, [dispatch]);

    const handleApprove = (reviewId: string, isApproved: boolean) => {
        console.log("Approving review with ID:", reviewId);
        dispatch(approveReviews({ reviewId, isApproved })).then((response) => {
            // Check if the response is successful
            if (response.meta.requestStatus === 'fulfilled') {
                // Update local state after approval
                setLocalApprovedReviews(prevReviews =>
                    prevReviews.map(review =>
                        review._id === reviewId ? { ...review, isApproved } : review
                    )
                );
            } else {
                console.error("Failed to approve review:", response);
            }
        });
    };

    return (
        <div className="p-5">
            <div className="container mx-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">Reviews</h2>
                </div>
                <table className="min-w-full table-auto">
                    <thead>
                        <tr>
                            <th className="px-4 py-2">Reviewer</th>
                            <th className="px-4 py-2">Rating</th>
                            <th className="px-4 py-2">Comment</th>
                            <th className="px-4 py-2">Approved</th>
                        </tr>
                    </thead>
                    <tbody>
                        {localApprovedReviews.map((review) => (
                            <tr key={review._id}>
                                <td className="border px-4 py-2">{review.author_name}</td>
                                <td className="border px-4 py-2">{review.rating}</td>
                                <td className="border px-4 py-2">{review.text}</td>
                                <td className="border px-4 py-2">
                                    <input
                                        type="checkbox"
                                        checked={review.isApproved} // This should reflect the correct isApproved status
                                        onChange={() => handleApprove(review._id, !review.isApproved)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {loadingReview && <p>Loading reviews...</p>}
                {errorReview && <p className="text-red-500">Error: {errorReview}</p>}
            </div>
        </div>
    );
};

export default ReviewManager;