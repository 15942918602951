import React, { useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { fetchApprovedReviews } from "features/review/reviewSlice";
import ReviewCard from "./ReviewCard";

const Reviews: React.FC = () => {
    const dispatch = useAppDispatch();
    const { approvedReviews, loadingReview, errorReview } = useAppSelector(
        (state) => state.reviews
    );

    const containerRef = useRef<HTMLDivElement>(null);
    const currentIndex = useRef(0);

    useEffect(() => {
        dispatch(fetchApprovedReviews());
    }, [dispatch]);

    // Automatic scrolling logic
    useEffect(() => {
        if (!approvedReviews || approvedReviews.length <= 1) return;

        const intervalId = setInterval(() => {
            const container = containerRef.current;
            if (!container) return;

            const children = container.children;
            if (!children || children.length === 0) return;

            // Scroll to the next card
            currentIndex.current = (currentIndex.current + 1) % children.length;
            const target = children[currentIndex.current] as HTMLElement;

            container.scrollTo({
                left: target.offsetLeft,
                behavior: "smooth",
            });
        }, 3000); // Adjust interval as needed

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [approvedReviews]);

    if (loadingReview) return <p>Loading reviews...</p>;
    if (errorReview) return <p>Error fetching reviews: {errorReview}</p>;
    if (!approvedReviews || approvedReviews.length === 0)
        return <p>No reviews available.</p>;

    return (
        <div className="w-full px-4">
            <div
                ref={containerRef}
                className="flex gap-4 overflow-x-auto"
                style={{
                    scrollSnapType: "x mandatory",
                    display: "flex",
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                }}
            >
                {approvedReviews.map((review) => (
                    <div
                        key={review._id}
                        className="flex-none w-80 h-auto"
                        style={{ scrollSnapAlign: "start" }}
                    >
                        <ReviewCard review={review} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Reviews;
