// src/app/store.ts
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import packageReducer from "../features/package/packageSlice";
import serviceReducer from "../features/service/serviceSlice";
import productReducer from "features/product/productSlice";
import timeslotReducer from "features/timeslot/timeslotSlice";
import promoCodeReducer from "features/promocode/promoCodeSlice";
import cartReducer from "features/cart/cartSlice";
import orderReducer from "features/orders/orderSlice";
import reviewReducer from '../features/review/reviewSlice';

import partnerReducer from "features/partner/partnerSlice";
import adsReducer from "features/ads/adsSlice";
export const store = configureStore({
  reducer: {
    users: userReducer,
    packages: packageReducer,
    services: serviceReducer,
    products: productReducer,
    ads: adsReducer,
    timeslots: timeslotReducer,
    promocodes: promoCodeReducer,
    partners: partnerReducer,
    cart: cartReducer,
    orders: orderReducer,
    reviews: reviewReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
