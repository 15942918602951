import React, { useEffect, useState } from "react";
import MyRouter from "routers/index";
import { requestNotificationPermission, getFCMToken, onForegroundMessage } from "./utils/firebaseConfig";
import { sendTokenToBackend } from "./utils/NotificationService";

function App() {
  const [token, setToken] = useState("");

  useEffect(() => {
    const initializeNotifications = async () => {
      const permission = await requestNotificationPermission();
      if (permission === "granted") {
        const token = await getFCMToken();
        if (token) {
          await sendTokenToBackend(token);
        }
      } else {
        console.warn('Notification permission not granted');
      }
    };

    initializeNotifications();

    // Set up the foreground message listener
    onForegroundMessage((payload) => {
      const title = payload.notification?.title || 'New Notification';
      const options = {
        body: payload.notification?.body || 'You have a new message.',
        icon: '/logo192.png', // Adjust the icon path as needed
      };

      // Display the notification
      new Notification(title, options);
    });
  }, [])

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
  );
}

export default App;
