import React, { useState, useEffect } from "react";
import { VehicleInformation } from "../data/types";

interface VehicleFormProps {
  initialValues: VehicleInformation; // VehicleInformation type from data/types.ts
  onSubmit: (vehicle: VehicleInformation) => void;
  onCancel: () => void;
}

const VehicleForm = ({
  initialValues,
  onSubmit,
  onCancel,
}: VehicleFormProps) => {
  const [vehicle, setVehicle] = useState<VehicleInformation>(initialValues);

  useEffect(() => {
    setVehicle(initialValues);
  }, [initialValues]);

  console.log("Vehicle", vehicle);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVehicle({ ...vehicle, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ ...vehicle }); // Call the provided onSubmit function with updated vehicle
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onCancel();
  };

  return (
    <form className="vehicle-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="make">Make:</label>
        <input
          type="text"
          name="make"
          id="make"
          value={vehicle.make || ""}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label htmlFor="model">Model:</label>
        <input
          type="text"
          name="model"
          id="model"
          value={vehicle.model || ""}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label htmlFor="year">Year:</label>
        <input
          type="number"
          name="year"
          id="year"
          value={vehicle.year || ""}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label htmlFor="licensePlateNumber">License Plate Number:</label>
        <input
          type="text"
          name="licensePlateNumber"
          id="licensePlateNumber"
          value={vehicle.licensePlateNumber || ""}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-actions">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md mr-2"
        >
          Save
        </button>
        <button
          type="button"
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default VehicleForm;
