import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  fetchUserProfile,
  updateUserProfile,
  fetchAddress,
  fetchVehicle,
  addNewAddress,
  addNewVehicle,
  deleteAddress,
  deleteVehicle,
  updateAddress,
  updateVehicle,
  updateAddressDefault,
  updateVehicleDefault,
} from "../features/user/userSlice";
import Modal from "shared/Modal/Modal";

import { FaEdit, FaTrash } from "react-icons/fa";
import { Address, VehicleInformation } from "../data/types";
import AddressForm from "components/AddressForm";
import VehicleForm from "components/VehicleForm";
import MapWithAutocomplete from "components/MapWithAutocomplete";
const UserProfileForm = () => {
  const dispatch = useAppDispatch();
  const { userInfo, addressInfo, vehicleInfo, loading, error } = useAppSelector(
    (state) => state.users
  );

  // State variables for addresses and vehicles (including edit mode)
  const [fullName, setFullName] = useState(userInfo?.fullName || "");
  const [email, setEmail] = useState(userInfo?.email || "");
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [vehicles, setVehicles] = useState<VehicleInformation[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditAddress, setIsEditAddress] = useState(false); // Track edit mode
  const [isNewAddress, setIsNewAddress] = useState(false); // Track new address
  const [newAddress, setNewAddress] = useState<Address>({
    name: "",
    street: "",
    city: "",
    country: "UAE",
    postalCode: "",
    isDefault: false,
  });
  const [isNewVehicle, setIsNewVehicle] = useState(false); // Track new vehicle
  const [editAddressIndex, setEditAddressIndex] = useState<number | null>(null);
  const [isEditVehicle, setIsEditVehicle] = useState(false);
  const [editVehicleIndex, setEditVehicleIndex] = useState<number | null>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(fetchUserProfile());
    dispatch(fetchAddress());
    dispatch(fetchVehicle());
  }, [dispatch]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const updatedUser = {
      fullName,
      phoneNumber,
      email,
    };
    dispatch(updateUserProfile(updatedUser));
    setIsEdit(false); // Close edit mode after submission
  };

  useEffect(() => {
    if (!userInfo) {
      dispatch(fetchUserProfile());
    } else {
      setFullName(userInfo.fullName || "");
      setEmail(userInfo.email || "");
      setPhoneNumber(userInfo.phoneNumber || "");
      setAddresses(addressInfo || []); // Update addresses from state
      setVehicles(vehicleInfo || []); // Update vehicles from state
    }
  }, [userInfo, dispatch, addressInfo, vehicleInfo]);

  const initialNewAddress: Address = {
    _id: "",
    name: "",
    street: "",
    city: "",
    country: "UAE",
    postalCode: "",
    isDefault: false,
  };
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  // Function to handle saving a new address
  // Function to handle saving a new address
  const handleSaveNewAddress = async (address: Address) => {
    console.log("New Address", address);
    // Remove _id field
    delete address._id;

    // Dispatch action to add new address to the backend
    await dispatch(addNewAddress(address));
    setIsModalOpen(false); // Close the modal
    setNewAddress({ // Reset the new address state
      name: "",
      street: "",
      city: "",
      country: "UAE",
      postalCode: "",
      isDefault: false,
    });
  };

  // Function to handle cancelling adding a new address
  const handleCancelNewAddress = () => {
    setIsModalOpen(false); // Close the modal
    setNewAddress({ // Reset the new address state
      name: "",
      street: "",
      city: "",
      country: "UAE",
      postalCode: "",
      isDefault: false,
    });
  };

  // Function to handle adding a new address
  const handleAddressSelect = (addressDetails: Address) => {
    setNewAddress(addressDetails);
    console.log(addressDetails); // Open new address form
  };

  const handleSaveAddress = async (address: Address) => {
    // Update address on backend using updateAddress
    const updatedAddresses = addresses.map((addr, i) =>
      i === editAddressIndex ? address : addr
    );
    dispatch(updateAddress(updatedAddresses));
    setIsEditAddress(false); // Close edit mode
    setEditAddressIndex(null);
  };

  const handleRemoveAddress = (addressId: string) => {
    // Implement logic to remove address from backend (consider updateAddress)
    dispatch(deleteAddress(addressId));
  };

  const handleEditAddress = (index: number) => {
    setIsEditAddress(true);
    setEditAddressIndex(index);
  };

  const handleCancelEditAddress = () => {
    setIsEditAddress(false);
    setEditAddressIndex(null);
  };

  const initialNewVehicle: VehicleInformation = {
    _id: "",
    make: "",
    model: "",
    year: 0,
    licensePlateNumber: "",
    isDefault: false,
  };

  const handleSaveNewVehicle = async (vehicle: VehicleInformation) => {
    // Remove _id field
    delete vehicle._id;

    // Dispatch action to add new vehicle to the backend
    dispatch(addNewVehicle(vehicle)); // Assuming you have an addNewVehicle action
    setIsNewVehicle(false); // Close new vehicle form
  };

  const handleCancelNewVehicle = () => {
    setIsNewVehicle(false); // Close new vehicle form
  };

  const handleAddVehicle = () => {
    setIsNewVehicle(true); // Open new address form
  };

  const handleRemoveVehicle = (vehicleId: string) => {
    // Implement logic to remove vehicle from backend (consider updateVehicle)
    dispatch(deleteVehicle(vehicleId));
  };

  const handleEditVehicle = (index: number) => {
    setIsEditVehicle(true);
    setEditVehicleIndex(index);
  };

  const handleSaveVehicle = async (vehicle: VehicleInformation) => {
    // Update vehicle on backend using updateVehicle
    const updatedVehicles = vehicles.map((veh, i) =>
      i === editVehicleIndex ? vehicle : veh
    );
    dispatch(updateVehicle(updatedVehicles));
    setIsEditVehicle(false); // Close edit mode
    setEditVehicleIndex(null);
  };

  const handleCancelEditVehicle = () => {
    setIsEditVehicle(false);
    setEditVehicleIndex(null);
  };

  const handleMakeAddressDefault = (addressId: string) => {
    dispatch(updateAddressDefault(addressId)); // Dispatch action with addressId
  };

  const handleMakeVehicleDefault = (vehicleId: string) => {
    dispatch(updateVehicleDefault(vehicleId));
  };

  return (
    <div className="mx-auto max-w-xl p-4 bg-gray-800 rounded-lg shadow-lg text-white">
      <form onSubmit={handleSubmit}>
        <section className="mb-4">
          <h2 className="text-lg font-semibold mb-2">User Information</h2>
          {!isEdit ? ( // Display information when not editing
            <div className="flex justify-between items-center">
              <div>
                <p className="mb-2">
                  <strong>Name:</strong> {fullName}
                </p>
                <p className="mb-2">
                  <strong>Email:</strong> {email}
                </p>
                <p className="mb-4">
                  <strong>Phone Number:</strong> {phoneNumber}
                </p>
              </div>
              <div>
                <button type="button" onClick={() => setIsEdit(true)}>
                  <FaEdit className="mr-1" />
                </button>
              </div>
            </div>
          ) : (
            // Display edit form when editing
            <>
              <div className="mb-4">
                <label htmlFor="fullName" className="block text-sm font-medium">
                  Full Name:
                </label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="mt-1 p-2 block w-full rounded-md bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium">
                  Email:
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 p-2 block w-full rounded-md bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phoneNumber"
                  className="block text-sm font-medium"
                >
                  Phone Number:
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="mt-1 p-2 block w-full rounded-md bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md mr-2"
                  onClick={() => {
                    const updatedUser = { fullName, phoneNumber };
                    dispatch(updateUserProfile(updatedUser));
                    setIsEdit(false); // Close edit mode after saving
                  }}
                >
                  {loading ? "Saving..." : "Save"}
                </button>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </section>
      </form>

      {/* Addresses Section */}
      <section className="mb-8">
        <h2 className="text-lg font-bold mb-2 text-white ">Addresses</h2>
        {addresses.map((address, index) => (
          <div
            key={address._id || index}
            className="mb-4 border-b border-gray-600 pb-4"
          >
            {isEditAddress && editAddressIndex === index ? (
              // Edit form for address
              <AddressForm
                initialValues={address}
                onSubmit={handleSaveAddress} // Make sure this function dispatches updateAddress action
                onCancel={handleCancelEditAddress}
              />
            ) : (
              // Display address details
              <>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-semibold mb-1">{address.name}</p>
                    <p>
                      {address.street}, {address.city} {address.postalCode}
                    </p>
                  </div>
                  {address.isDefault && (
                    <span className="bg-green-500 text-white text-xs font-semibold py-1 px-2 rounded-md">
                      Default
                    </span>
                  )}
                </div>
                <div className="flex justify-end mt-2">
                  {!address.isDefault && (
                    <button
                      onClick={() =>
                        address._id && handleMakeAddressDefault(address._id)
                      } // Pass addressId to the function
                      className="mr-2"
                    >
                      <span className="font-bold text-sm pr-4">
                        {" "}
                        Make Default
                      </span>
                    </button>
                  )}

                  <button
                    onClick={() =>
                      address._id && handleRemoveAddress(address._id)
                    }
                  >
                    <FaTrash />
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
        <button
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md"
          onClick={openModal}
        >
          Add New Address
        </button>
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="m-4 ">
              {" "}
              {/* Increase max width here */}
              <MapWithAutocomplete onAddressSelect={handleAddressSelect} />
              <AddressForm
                initialValues={newAddress}
                onSubmit={handleSaveNewAddress}
                onCancel={handleCancelNewAddress}
              />
            </div>
          </Modal>
        )}
      </section>

      {/* Vehicles Section */}
      <section>
        <h2 className="text-lg font-bold mb-2 text-white">Vehicles</h2>
        {vehicles.map((vehicle, index) => (
          <div
            key={vehicle._id || index}
            className="mb-4 border-b border-gray-600 pb-4"
          >
            {isEditVehicle && editVehicleIndex === index ? (
              // Edit form for vehicle
              <VehicleForm
                initialValues={vehicle}
                onSubmit={handleSaveVehicle} // Make sure this function dispatches updateVehicle action
                onCancel={handleCancelEditVehicle}
              />
            ) : (
              // Display vehicle details
              <>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-semibold mb-1">
                      {vehicle.make} &nbsp; {vehicle.model} &nbsp;{" "}
                      {vehicle.year}
                    </p>
                    <p>{vehicle.licensePlateNumber}</p>
                  </div>

                  {vehicle.isDefault && (
                    <span className="bg-green-500 text-white text-xs font-semibold py-1 px-2 rounded-md">
                      Default
                    </span>
                  )}
                  {!vehicle.isDefault && (
                    <button
                      onClick={() =>
                        vehicle._id && handleMakeVehicleDefault(vehicle._id)
                      }
                      className="mr-2"
                    >
                      <span className="font-bold text-sm pr-4">
                        {" "}
                        Make Default
                      </span>
                    </button>
                  )}
                </div>
                <div className="flex justify-end mt-2">
                  <button onClick={() => handleEditVehicle(index)}>
                    <FaEdit className="mr-1" />
                  </button>
                  <button
                    onClick={() =>
                      vehicle._id && handleRemoveVehicle(vehicle._id)
                    }
                  >
                    <FaTrash className="mr-1" />
                  </button>
                </div>
              </>
            )}
          </div>
        ))}
        <button
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md"
          onClick={handleAddVehicle}
        >
          Add New Vehicle
        </button>
        {isNewVehicle && (
          <VehicleForm
            initialValues={initialNewVehicle}
            onSubmit={handleSaveNewVehicle}
            onCancel={handleCancelNewVehicle}
          />
        )}
      </section>
    </div>
  );
};

export default UserProfileForm;
