import React from 'react';
import { Review } from 'data/types';

interface ReviewCardProps {
    review: Review;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ review }) => {
    const renderStars = (rating: number) => (
        <div className="flex justify-center mb-2">
            {[...Array(5)].map((_, index) => (
                <span
                    key={index}
                    className={`text-lg ${index < rating ? 'text-yellow-400' : 'text-gray-300'}`}
                >
                    ★
                </span>
            ))}
        </div>
    );

    return (
        <div className="bg-white shadow-md rounded-lg p-4 border border-gray-200 flex flex-col items-center text-center w-full max-w-sm h-auto">
            <h3 className="text-lg font-semibold text-gray-800 mb-1 truncate w-full">
                {review.author_name}
            </h3>
            {renderStars(review.rating)}
            <p
                className="text-gray-600 leading-relaxed text-sm mt-2 break-words"
                style={{
                    display: 'block',
                    overflow: 'hidden',
                    whiteSpace: 'normal',
                    WebkitLineClamp: 3, // Max 3 lines of text visible
                    WebkitBoxOrient: 'vertical',
                    height: '80px', // Limit text height
                    lineHeight: '1.5em', // Control the line height
                }}
            >
                {review.text}
            </p>
        </div>
    );
};

export default ReviewCard;
