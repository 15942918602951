import React, { useEffect, useState } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Modal from "shared/Modal/Modal";
import Input from "shared/Input/Input";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchOrders, orderDetails } from "features/orders/orderSlice";

const OrderManager = () => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state) => state.orders.orders);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const handleOrderDetails = (orderId: string) => {
    const order = orders.find((order: any) => order._id === orderId);
    setSelectedOrder(order);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Helper function to safely get user email
  const getUserEmail = (order: any) => {
    return order.user?.email || 'User Deleted';
  };

  return (
    <div className="p-5">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <table className="min-w-full table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">Order ID</th>
                <th className="px-4 py-2">Customer Email</th>
                <th className="px-4 py-2">Total Price</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order: any) => (
                <tr key={order._id}>
                  <td className="border px-4 py-2">{order._id}</td>
                  <td className="border px-4 py-2">{getUserEmail(order)}</td>
                  <td className="border px-4 py-2">{order.total}</td>
                  <td
                    className={`border px-4 py-2 ${order.status === "Pending"
                        ? "bg-yellow-500"
                        : order.status === "Paid"
                          ? "bg-green-500"
                          : order.status === "Completed"
                            ? "bg-green-800"
                            : "bg-gray-500"
                      }`}
                  >
                    {order.status}
                  </td>
                  <td className="border px-4 py-2">
                    <ButtonPrimary onClick={() => handleOrderDetails(order._id)}>
                      View Details
                    </ButtonPrimary>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedOrder && (
        <Modal isOpen={showModal} onClose={closeModal}>
          <div className="p-4">
            <p className="text-white">
              <strong>Order ID:</strong> {selectedOrder._id}
            </p>
            <p className="text-white">
              <strong>Customer Email:</strong> {getUserEmail(selectedOrder)}
            </p>
            <p className="text-white">
              <strong>Date:</strong>{" "}
              {new Date(selectedOrder.date).toLocaleDateString()}
            </p>
            <p className="text-white">
              <strong>Time:</strong> {selectedOrder.time}
            </p>
            <p className="text-white">
              <strong>Status:</strong>{" "}
              <span
                className={`inline-block py-1 px-2 rounded ${selectedOrder.status === "Pending"
                    ? "bg-yellow-500"
                    : selectedOrder.status === "Confirmed"
                      ? "bg-green-500"
                      : "bg-gray-500"
                  }`}
              >
                {selectedOrder.status}
              </span>
            </p>
            {selectedOrder.car && (
              <>
                <p className="text-white">
                  <strong>Car:</strong> {selectedOrder.car.make}{" "}
                  {selectedOrder.car.model}
                </p>
                <p className="text-white">
                  <strong>License Plate Number:</strong>{" "}
                  {selectedOrder.car.licensePlateNumber}
                </p>
              </>
            )}
            {selectedOrder.address && (
              <>
                <p className="text-white">
                  <strong>Address:</strong>
                </p>
                <ul className="ml-4 text-white">
                  <li>Street: {selectedOrder.address.street}</li>
                  <li>City: {selectedOrder.address.city}</li>
                  <li>Postal Code: {selectedOrder.address.postalCode}</li>
                  <li>Country: {selectedOrder.address.country}</li>
                </ul>
              </>
            )}
            <p className="text-white">
              <strong>Items:</strong>
            </p>
            <ul className="ml-4 text-white">
              {selectedOrder.cartItems.map((item: any, index: number) => (
                <li key={index}>
                  {item.name} - Quantity: {item.quantity} - Price: {item.price}
                </li>
              ))}
            </ul>
            <p className="text-white mt-4">
              <strong>Total Price:</strong> {selectedOrder.total}
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default OrderManager;
