// AddressForm.js

import React, { useEffect, useState } from "react";
import { Address } from "../data/types";

interface AddressFormProps {
  initialValues: Address;
  onSubmit: (address: Address) => void;
  onCancel: () => void;
}

const AddressForm = ({
  initialValues,
  onSubmit,
  onCancel,
}: AddressFormProps) => {
  const [address, setAddress] = useState<Address>(initialValues);

  useEffect(() => {
    setAddress(initialValues);
  }, [initialValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress({ ...address, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ ...address }); // Include isDefault in the submitted address
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onCancel();
  };

  return (
    <form className="address-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          name="name"
          id="name"
          value={address.name || ""}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label htmlFor="street">Street:</label>
        <input
          type="text"
          name="street"
          id="street"
          value={address.street || ""}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label htmlFor="city">City:</label>
        <input
          type="text"
          name="city"
          id="city"
          value={address.city || ""}
          onChange={handleChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label htmlFor="country">Country:</label>
        <input
          type="text"
          name="country"
          id="country"
          value="UAE" // Default country value
          onChange={handleChange}
          className="input-field"
          disabled // Disable editing for country
        />
      </div>

      <div className="form-actions">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md mr-2"
        >
          Save
        </button>
        <button
          type="button"
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddressForm;
