// frontend/src/features/review/reviewSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Review } from 'data/types';
const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

interface ApproveReviewPayload {
    reviewId: string;
    isApproved: boolean;
}

interface ReviewState {
    approvedReviews: Review[];
    loadingReview: boolean;
    errorReview: string | null;
}

export const getAllReviews = createAsyncThunk('reviews/getAllReviews',
    async () => {
        try {
            const response = await axiosInstance.get('/api/review')
            return response.data;
        } catch (error) {
            return error;
        }
    }
)

export const approveReviews = createAsyncThunk<Review, ApproveReviewPayload, { rejectValue: string }>(
    'reviews/approveReviews',
    async ({ reviewId, isApproved }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                return rejectWithValue("No token found");
            }
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            };
            const { data } = await axiosInstance.put(`/api/review/${reviewId}`, { isApproved }, config);
            return data; // Return the updated review
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

export const fetchGoogleReviews = createAsyncThunk('reviews/fetchGoogleReviews',
    async (placeId: string, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/api/review/fetch-google/${placeId}`);
            return response.data.map((review: Review) => ({ ...review, isApproved: false }));
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
)

export const fetchApprovedReviews = createAsyncThunk(
    'reviews/fetchApproved',
    async () => {
        const response = await axiosInstance.get('/api/review/approved');
        return response.data;
    }
);

const reviewSlice = createSlice({
    name: 'reviews',
    initialState: {
        approvedReviews: [] as Review[],
        loadingReview: false,
        errorReview: null,
    } as ReviewState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchApprovedReviews.pending, (state) => {
                state.loadingReview = true;
                state.errorReview = null;
            })
            .addCase(fetchApprovedReviews.fulfilled, (state, action) => {
                state.loadingReview = false;
                state.approvedReviews = action.payload;
            })
            .addCase(fetchApprovedReviews.rejected, (state, action) => {
                state.loadingReview = false;
                state.errorReview = action.error.message || null;
            })
            .addCase(fetchGoogleReviews.pending, (state) => {
                state.loadingReview = true;
                state.errorReview = null;
            })
            .addCase(fetchGoogleReviews.fulfilled, (state, action) => {
                state.loadingReview = false;
                state.approvedReviews = action.payload;
            })
            .addCase(fetchGoogleReviews.rejected, (state, action) => {
                state.loadingReview = false;
                state.errorReview = action.error.message || null;
            })
            .addCase(approveReviews.pending, (state) => {
                state.loadingReview = true;
                state.errorReview = null;
            })
            .addCase(approveReviews.fulfilled, (state, action) => {
                state.loadingReview = false;
                state.approvedReviews.push(action.payload);
            })
            .addCase(approveReviews.rejected, (state, action) => {
                state.loadingReview = false;
                state.errorReview = action.error.message || null;
            })
            .addCase(getAllReviews.pending, (state) => {
                state.loadingReview = true;
                state.errorReview = null;
            })
            .addCase(getAllReviews.fulfilled, (state, action) => {
                state.loadingReview = false;
                state.approvedReviews = action.payload; // Assuming you want to replace approvedReviews with all reviews
            })
            .addCase(getAllReviews.rejected, (state, action) => {
                state.loadingReview = false;
                state.errorReview = action.error.message || null;
            });
    },
});

export default reviewSlice.reducer;