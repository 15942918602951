import { initializeApp } from "firebase/app";
import { getAuth, Auth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from 'axios'
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBzMFQ0tVwxrgXMR7b8xdwEuIrkt_Y3SOc",
  authDomain: "carveel-carwash.firebaseapp.com",
  projectId: "carveel-carwash",
  storageBucket: "carveel-carwash.appspot.com",
  messagingSenderId: "25831624167",
  appId: "1:25831624167:web:9051d77e06b98985764142",
  measurementId: "G-48X6FS68HN",
};

export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app)

// frontend/src/firebaseMessaging.ts
export const requestNotificationPermission = async () => {
  try {
    console.log('Requesting notification permission...');
    const permission = await Notification.requestPermission();
    console.log('Permission:', permission);

    if (permission === 'granted') {
      const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
      console.log('Generated FCM token:', token);

      // Send the token to the backend
      await axios.post(`${process.env.REACT_APP_API_URL}/api/users/fcm-token`, { fcmToken: token }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      return permission;
    } else if (permission === 'denied') {
      console.warn('Notifications were denied by the user');
      return permission; // Or handle as needed
    } else {
      console.warn('Notification permission request dismissed or defaulted');
      return permission;
    }
  } catch (error) {
    console.error('Error requesting notification permission:', error);
    throw error;
  }
};


export const getFCMToken = () => {
  return getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        alert(
          "No registration token available. Request permission to generate one."
        );
        return null;
      }
    })
    .catch((err) => {
      alert("An error occurred while retrieving token - " + err);
      return null;
    });
};


export const onForegroundMessage = (callback: (payload: any) => void) => {
  console.log("Listening for foreground messages...");
  onMessage(messaging, (payload) => {
    console.log('Foreground message received:', payload);
    if (payload.notification) {
      const title = payload.notification.title || 'New Notification';
      const options = {
        body: payload.notification.body || 'You have a new message.',
        icon: '/logo192.png', // Adjust the icon path as needed
      };

      // Display the notification
      new Notification(title, options);
    }
  });
};

export const auth: Auth = getAuth(app); // Ensure that `auth` is typed as `Auth`
export { messaging }

